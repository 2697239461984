import { defineStore } from 'pinia'
import { entries } from 'lodash'

export const useBookingStore = defineStore({
  id: 'booking',
  state: (): any => {
    return {
      sortBy: 'CREATED_AT',
      sortDirection: 'DESC',
      perPage: 25,
      offSet: 0,
      filters: {
        reference: null,
        description: null,
        currency: {
          operation: 'INCLUDE',
          values: ['GBP', 'USD', 'CAD', 'NZD', 'AUD', 'INR'],
        },
        customer: {
          firstName: null,
          lastName: null,
          email: null,
        },
        passengers: {
          firstName: null,
          lastName: null,
          email: null,
        },
        status: {
          include: ['CANCELLED', 'CONFIRMED', 'PENDING', 'ON_HOLD', 'ERROR'],
          exclude: [],
        },
        tags: {
          match: 'ANY',
          include: [],
          exclude: [],
        },
      },
    }
  },
  getters: {
    hasFilters: (state) => {
      const filters: any[] = []
      entries(state.filters).forEach((entry) => {
        const [key, value]: any = entry
        if (
          (key === 'reference' && value !== null) ||
          (key === 'description' && value !== null)
        ) {
          filters.push(entry)
        }
        if (key === 'currency' && value!.values && value!.values.length < 6) {
          filters.push(entry)
        }
        if (
          (key === 'customer' && value!.firstName !== null) ||
          (key === 'passenger' && value!.firstName !== null) ||
          (key === 'customer' && value!.lastName !== null) ||
          (key === 'passenger' && value!.lastName !== null) ||
          (key === 'customer' && value!.email !== null) ||
          (key === 'passenger' && value!.email !== null)
        ) {
          filters.push(entry)
        }
        if (key === 'status' && value!.include && value!.include.length < 5) {
          filters.push(entry)
        }
        if (
          (key === 'tags' && value!.include && value!.include.length) ||
          (key === 'tags' && value!.exclude && value!.exclude.length)
        ) {
          filters.push(entry)
        }
      })
      return filters && filters.length > 0
    },
  },
  actions: {
    resetFilters() {
      this.filters = {
        reference: null,
        description: null,
        currency: {
          operation: 'INCLUDE',
          values: ['GBP', 'USD', 'CAD', 'NZD', 'AUD', 'INR'],
        },
        customer: {
          firstName: null,
          lastName: null,
          email: null,
        },
        passengers: {
          firstName: null,
          lastName: null,
          email: null,
        },
        status: {
          include: ['CANCELLED', 'CONFIRMED', 'PENDING', 'ON_HOLD', 'ERROR'],
          exclude: [],
        },
        tags: {
          match: 'ANY',
          include: [],
          exclude: [],
        },
      }
    },
  },
  persist: {
    storage: sessionStorage,
    paths: ['sortBy', 'sortDirection', 'filters'],
  },
})
